import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import NotificationToast from "../../../components/Helpers/NotificationToast";
import * as Yup from "yup";
import { Tab } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import SettingsSelect from "../../Forms/SettingsSelect";
import SettingsTextInput from "../../Forms/SettingsTextInput";
import SettingsDatePicker from "../../Forms/SettingsDatePicker";
import SettingsSwitch from "../../Forms/SettingsSwitch";
import SubmitButton from "../../Forms/SubmitButton";
import AppLoader from "../../Helpers/AppLoader";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import CustomButton from "../../Helpers/CustomButton";

import { faBuildingCircleCheck, faFile, faMessage, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faCreditCard, faDollarSign, faUser, faUserGroup } from "@fortawesome/pro-solid-svg-icons";
import FileUpload from "../../Forms/FileUpload";
import FileDisplaySimple from "../../Forms/FileDisplaySimple";
// import { getBooking } from "../../../store/booking/bookingActions";
import PaymentForm from "../PaymentForm";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function ApplicationFormUCStep4Frm({ url, ...props }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [fileUploadInformation, setFileUploadInformation] = useState([]);
    const [satisfied, setSatisfied] = useState(false);
    // const [unitTypes, setUnitTypes] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [initialValues, setInitialValues] = useState({
        student_type: props.type,
        gender: props.gender,
        name: "",
        surname: "",
        email: "",
        cell: "",
        title: "",
        nationality: "",
        id_number: "",
        date_of_birth: "",
        student_number: "",
        ethnicity: "",
        institution_study: "",
        institution_campus: "",
        faculty: "",

        property_id: props.property_id,
        bed: props.bed,
        waiting_bed_id: props.waitbed,
        study_year: parseInt(props.year),

        kin_title: "",
        kin_name: "",
        kin_surname: "",
        kin_relationship: "",
        kin_contact: "",
        kin_email: "",
        kin_address: "",
        kin_id_number: "",
        kin_suburb: "",
        kin_postal: "",
        kin_city: "",
        kin_tel_contact: "",
        kin_work_contact: "",

        referral: "",
        assisted_by: "",

        parent_name: "",
        parent_surname: "",
        parent_cell: "",
        parent_id_number: "",
        parent_email: "",

        price_terms: false,
        cash_bursary: false,
        refundable_booking: false,
        cash_back: false,
        secure_bed: false,
        popi: false,

        url: url,
    });
    const [current_booking, setCurrentBooking] = useState({});
    const [current_bed, setCurrentBed] = useState({});
    const [lookups, setLookups] = useState([]);
    const [yeartext, setYeartext] = useState("");

    // console.log(props);
    // console.log(initialValues);

    const [property_id, setPropertyId] = useState(props.property_id);
    const [type, setType] = useState(props.type);
    const [year, setYear] = useState(props.year);
    const [gender, setGender] = useState(props.gender);
    const [unit, setUnit] = useState(props.unit);
    const [bed, setBed] = useState(props.bed);

    const [bankList, setBankList] = useState([]);

    const subNavigation = [
        { name: "Your Booking", icon: faBuildingCircleCheck },
        { name: "Student Details", icon: faUser },
        { name: "Next of Kin", icon: faUserGroup },
        { name: "Referral Information", icon: faMessage },
        { name: "Document Uploads", icon: faFile },
    ];

    const student_types = [
        { label: "Choose an option", value: "" },
        { label: "NSFAS UJ", value: 4 },
        { label: "NSFAS WITS", value: 5 },
        { label: "CJC", value: 6 },
        { label: "NSFAS TVET", value: 1 },
        { label: "Bursary", value: 2 },
        { label: "Private", value: 3 },
    ];

    if ((current_booking && (current_booking.student_type == 2 || current_booking.student_type == 3)) || props.type == 2 || props.type == 3) {
        subNavigation.push({ name: "Responsible for Funding", icon: faDollarSign });
    }
    if (current_booking && current_booking.rates && current_booking.rates.length > 0) subNavigation.push({ name: "Deposit Payment", icon: faCreditCard });

    // console.log(current_booking);
    // console.log(year);

    // ENABLE WHEN GO LIVE
    // window.onbeforeunload = function () {
    //     return "Data will be lost if you leave the page, are you sure?";
    // };
    useEffect(async () => {
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}paystack_banks`,
        })
            .then((response) => {
                let banks = response.data.data;
                let bankList = [];

                Object.keys(banks).forEach((key) => {
                    bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                });

                setBankList(bankList);
            })
            .catch((error) => {
                alert("Bank failed");
            });

        if (url !== "" && url != "0" && !current_booking?.id) {
            setLoading(true);

            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setCurrentBooking(response.data);

                    setInitialValues({
                        student_type: response.data.student_type,
                        name: response.data.user.name,
                        surname: response.data.user.surname !== null ? response.data.user.surname : "",
                        email: response.data.user.email,
                        cell: response.data.user.cell !== "" ? "+" + response.data.user.cell : "",
                        title: response.data.user.profile && response.data.user.profile.title !== null ? response.data.user.profile.title : "",
                        nationality: response.data.user.profile && response.data.user.profile.nationality !== null ? response.data.user.profile.nationality : "",
                        id_number: response.data.user.profile && response.data.user.profile.id_number !== null ? response.data.user.profile.id_number : "",
                        date_of_birth: response.data.user.profile && response.data.user.profile.date_of_birth !== null ? response.data.user.profile.date_of_birth : "",
                        student_number: response.data.user.profile && response.data.user.profile.student_number !== null ? response.data.user.profile.student_number : "",
                        ethnicity: response.data.user.profile && response.data.user.profile.ethnicity !== null ? response.data.user.profile.ethnicity : "",
                        institution_study: response.data.user.profile && response.data.user.profile.institution_study !== null ? response.data.user.profile.institution_study : "",
                        institution_campus: response.data.user.profile && response.data.user.profile.institution_campus !== null ? response.data.user.profile.institution_campus : "",
                        faculty: response.data.user.profile && response.data.user.profile.faculty !== null ? response.data.user.profile.faculty : "",
                        banking_bank: response.data.user.profile?.banking_bank ? response.data.user.profile.banking_bank : "",
                        banking_account_type: response.data.user.profile?.banking_account_type ? response.data.user.profile.banking_account_type : "",
                        banking_branch: response.data.user.profile?.banking_branch ? response.data.user.profile.banking_branch : "",
                        banking_branch_code: response.data.user.profile?.banking_branch_code ? response.data.user.profile.banking_branch_code : "",
                        banking_account_number: response.data.user.profile?.banking_account_number ? response.data.user.profile.banking_account_number : "",
                        banking_account_holder: response.data.user.profile?.banking_account_holder ? response.data.user.profile.banking_account_holder : "",

                        study_year: response.data?.user?.profile && response.data?.user?.profile?.study_year !== null ? response.data.user.profile.study_year : 0,
                        gender: gender !== null && gender > 0 ? gender : response.data.user.profile ? response.data.user.profile.gender : 0,
                        property_id: response.data.property_id,
                        bed: response.data.bed_id > 0 ? response.data.bed_id : 0,
                        waiting_bed_id: response.data.waiting_bed_id > 0 ? response.data.waiting_bed_id : 0,

                        kin_title: response.data.user.next_of_kin && response.data.user.next_of_kin.title !== null ? response.data.user.next_of_kin.title : "",
                        kin_name: response.data.user.next_of_kin && response.data.user.next_of_kin.name !== null ? response.data.user.next_of_kin.name : "",
                        kin_surname: response.data.user.next_of_kin && response.data.user.next_of_kin.surname !== null ? response.data.user.next_of_kin.surname : "",
                        kin_relationship: response.data.user.next_of_kin && response.data.user.next_of_kin.relationship !== null ? response.data.user.next_of_kin.relationship : "",
                        kin_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.contact !== null ? "+" + response.data.user.next_of_kin.contact : "",
                        kin_email: response.data.user.next_of_kin && response.data.user.next_of_kin.email !== null ? response.data.user.next_of_kin.email : "",
                        kin_address: response.data.user.next_of_kin && response.data.user.next_of_kin.address !== null ? response.data.user.next_of_kin.address : "",
                        kin_id_number: response.data.user.next_of_kin && response.data.user.next_of_kin.id_number !== null ? response.data.user.next_of_kin.id_number : "",
                        kin_suburb: response.data.user.next_of_kin && response.data.user.next_of_kin.suburb !== null ? response.data.user.next_of_kin.suburb : "",
                        kin_postal: response.data.user.next_of_kin && response.data.user.next_of_kin.postal !== null ? response.data.user.next_of_kin.postal : "",
                        kin_city: response.data.user.next_of_kin && response.data.user.next_of_kin.city !== null ? response.data.user.next_of_kin.city : "",
                        kin_tel_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.tel_contact !== null ? "+" + response.data.user.next_of_kin.tel_contact : "",
                        kin_work_contact: response.data.user.next_of_kin && response.data.user.next_of_kin.work_contact !== null ? "+" + response.data.user.next_of_kin.work_contact : "",

                        referral_name: response.data.user.premises ? response.data.user.premises.name : "",
                        referral_surname: response.data.user.premises ? response.data.user.premises.surname : "",
                        referral_contact: response.data.user.premises && response.data.user.premises.contact ? "+" + response.data.user.premises.contact : "",
                        referral_id_number: response.data.user.premises ? response.data.user.premises.id_number : "",
                        referral: response.data.user.premises ? parseInt(response.data.user.premises.referral) : "",
                        assisted_by: response.data.user.premises && response.data.user.premises.assisted_by !== null ? response.data.user.premises.assisted_by : "",

                        parent_name: response.data.user.parent && response.data.user.parent.name !== null ? response.data.user.parent.name : "",
                        parent_surname: response.data.user.parent && response.data.user.parent.surname !== null ? response.data.user.parent.surname : "",
                        parent_cell: response.data.user.parent && response.data.user.parent.cell !== null ? "+" + response.data.user.parent.cell : "",
                        parent_id_number: response.data.user.parent && response.data.user.parent.id_number !== null ? response.data.user.parent.id_number : "",
                        parent_email: response.data.user.parent && response.data.user.parent.email !== null ? response.data.user.parent.email : "",

                        url: url,

                        price_terms: response.data.pricing_terms ? response.data.pricing_terms : null,
                        cash_bursary: response.data.cash_bursary ? response.data.cash_bursary : null,
                        refundable_booking: response.data.refundable_booking ? response.data.refundable_booking : null,
                        cash_back: response.data.cash_back ? response.data.cash_back : null,
                        secure_bed: response.data.secure_bed ? response.data.secure_bed : null,
                    });

                    if (response.data.pricing_terms == 1) {
                        setSatisfied(true);
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    // window.location.href = "/404";
                });
        } else {
            if (url == "0" && !current_bed?.id) {
                //get_bed_info
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}get_bed_info/${props.waitbed > 0 ? props.waitbed : props.bed}`,
                })
                    .then((response) => {
                        // setCurrentBooking(response.data);
                        // console.log(response.data);
                        setCurrentBed(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                        // window.location.href = "/404";
                    });
            }
        }

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}uc_lookups_for_students`,
        })
            .then((response) => {
                setLookups(response.data);

                const years = response.data[8];

                years.map((y) => {
                    if (y.value === parseInt(year)) {
                        setYeartext(y.label);
                    } else if (y.value == current_booking?.user?.profile?.study_year) {
                        setYeartext(y.label);
                    }
                });
            })
            .catch((error) => {
                alert("lookups failed");
            });
    }, [setInitialValues]);

    if (loading === true) return <AppLoader />;

    function formatToFloat(value) {
        if (value) {
            return parseFloat(value.replace(/,/g, ""));
        } else {
            return value;
        }
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            boxShadow: "none",
            border: state.isFocused && "none" ? "1px solid #9F7D38" : "1px solid #d1d5db",
            cursor: "pointer",
            "&:hover": {
                border: state.isFocused ? "1px solid #9F7D38" : "1px solid #d1d5db",
                cursor: "pointer",
            },
            borderRadius: "10px",
            padding: 0,
        }),
        container: (provided, state) => ({
            ...provided,
            padding: 0,
            border: 0,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: "#e7ebe5",
        }),
    };

    if (
        current_booking &&
        current_booking.rates &&
        current_booking.rates.length > 0 &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== "" &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== null
    ) {
        return (
            <main className="mt-14">
                <div className="max-w-7xl mx-auto pb-2 px-4">
                    <h2>Your lease has been signed, no futher updates to your information are allowed.</h2>
                </div>
            </main>
        );
    }

    return (
        <>
            {satisfied === false ? (
                <>
                    <div className="mt-14 ">
                        <div className="max-w-7xl mx-auto pb-2 px-4">
                            <div className="w-full">
                                <div className="flex align-center justify-between">
                                    <p className="text-gray-600 my-4">
                                        Please click <strong>I am happy</strong> to proceed.
                                    </p>
                                    <div className="text-right">
                                        <CustomButton text={"I am Happy"} onClick={() => setSatisfied(true)} />
                                    </div>
                                </div>
                                <div className="xl:grid xl:grid-cols-12">
                                    <Tab.Group>
                                        <Tab.List
                                            className="pb-6 lg:pb-24 bg-light_background xl:col-span-2 w-full xl:w-72 border-0"
                                            style={{ borderTopLeftRadius: "32px", borderBottomLeftRadius: "32px" }}
                                        >
                                            <Tab
                                                key={"satisfied_booking"}
                                                className={({ selected }) =>
                                                    classNames(
                                                        "group w-full text-sm flex items-center hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none mb-6 rounded-l-full",
                                                        selected ? "bg-gray-100 text-primary" : "bg-light_background"
                                                    )
                                                }
                                            >
                                                <div className="bg-primary mr-5 h-16 w-16 rounded-full flex items-center justify-center text-white">
                                                    <FontAwesomeIcon icon={faBuildingCircleCheck} aria-hidden="true" style={{ fontSize: "22px" }} />
                                                </div>
                                                Your Booking
                                            </Tab>
                                        </Tab.List>
                                        <Tab.Panels className="xl:ml-20 my-6 xl:my-0 bg-gray-100 xl:col-span-10" style={{ borderTopRightRadius: "32px", borderBottomRightRadius: "32px" }}>
                                            <Tab.Panel className="xl:col-span-9 pb-12">
                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-2 gap-12 xl:px-6 uppercase">
                                                    <div>
                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">BUILDING:</p>
                                                            <p>{!current_booking?.id ? current_bed?.property?.name : current_booking?.property?.name}</p>
                                                        </div>

                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">FLOOR:</p>
                                                            <p>{!current_booking?.id ? current_bed?.unit?.floor.name : current_booking?.unit?.floor?.name}</p>
                                                        </div>

                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">Bed:</p>
                                                            <p>
                                                                {/* {!current_booking?.id ? current_bed?.unit?.name + " " + current_bed?.name : current_booking?.unit?.name + " " + current_booking?.bed?.name} */}
                                                                {!current_booking?.id
                                                                    ? current_bed?.unit?.name + " " + current_bed?.name + (props.waitbed > 0 ? " or similar" : "")
                                                                    : (current_booking?.waiting_bed_id > 0 ? current_booking?.waiting_bed?.name : current_booking?.bed?.name) +
                                                                      (current_booking?.waiting_bed_id > 0 ? " or similar." : "")}
                                                            </p>
                                                        </div>

                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">ROOM TYPE:</p>
                                                            <p>{!current_booking?.id ? current_bed?.unit_type?.name : current_booking?.unit_type?.name}</p>
                                                        </div>

                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">GENDER:</p>
                                                            <p>
                                                                {current_booking?.user?.profile?.gender === 1
                                                                    ? "Male"
                                                                    : current_booking?.user?.profile?.gender === 2
                                                                    ? "Female"
                                                                    : gender == 1
                                                                    ? "Male"
                                                                    : gender == 2
                                                                    ? "Female"
                                                                    : "NOT CHOSEN"}
                                                            </p>
                                                        </div>
                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">MONTHLY RENTAL: </p>
                                                            {current_booking?.id ? (
                                                                <p>
                                                                    {initialValues.student_type == 2
                                                                        ? current_booking?.unit_type?.full_price?.[0]?.bursary_price > 0
                                                                            ? "R " + current_booking?.unit_type?.full_price?.[0]?.bursary_price + "/pm"
                                                                            : "R " + current_booking?.unit_type?.bursary_price + "/pm"
                                                                        : initialValues.student_type == 3
                                                                        ? current_booking?.unit_type?.full_price?.[0]?.private_price > 0
                                                                            ? "R " + current_booking?.unit_type?.full_price?.[0]?.private_price + "/pm"
                                                                            : "R " + current_booking?.unit_type?.private_price + "/pm"
                                                                        : current_booking?.unit_type?.full_price?.[0]?.nsfas_price > 0
                                                                        ? "R " + current_booking?.unit_type?.full_price?.[0]?.nsfas_price + "/pm"
                                                                        : current_booking?.unit_type?.nsfas_price === 0
                                                                        ? "NSFAS Rate"
                                                                        : "R " + current_booking?.unit_type?.nsfas_price + "/pm"}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {initialValues.student_type == 2
                                                                        ? current_bed?.full_price?.[0]?.bursary_price > 0
                                                                            ? "R " + current_bed?.full_price?.[0]?.bursary_price + "/pm"
                                                                            : "R " + current_bed?.unit_type?.bursary_price + "/pm"
                                                                        : initialValues.student_type == 3
                                                                        ? current_bed?.full_price?.[0]?.private_price > 0
                                                                            ? "R " + current_bed?.full_price?.[0]?.private_price + "/pm"
                                                                            : "R " + current_bed?.unit_type?.private_price + "/pm"
                                                                        : current_bed?.full_price?.[0]?.nsfas_price > 0
                                                                        ? "R " + current_bed?.full_price?.[0]?.nsfas_price + "/pm"
                                                                        : current_bed?.unit_type?.nsfas_price === 0
                                                                        ? "NSFAS Rate"
                                                                        : "R " + current_bed?.unit_type?.nsfas_price + "/pm"}
                                                                </p>
                                                            )}
                                                        </div>

                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">
                                                                {initialValues.student_type == 2 || initialValues.student_type == 3 ? "DEPOSIT" : "UPFRONT NON-REFUNDABLE FEE"}:{" "}
                                                            </p>
                                                            {current_booking?.id ? (
                                                                <p>
                                                                    {initialValues.student_type == 2
                                                                        ? current_booking?.unit_type?.full_price?.[0]?.bursary_deposit > 0
                                                                            ? "R " + current_booking?.unit_type?.full_price?.[0]?.bursary_deposit
                                                                            : "R " + current_booking?.unit_type?.bursary_deposit
                                                                        : initialValues.student_type == 3
                                                                        ? current_booking?.unit_type?.full_price?.[0]?.private_deposit > 0
                                                                            ? "R " + current_booking?.unit_type?.full_price?.[0]?.private_deposit
                                                                            : "R " + current_booking?.unit_type?.private_deposit
                                                                        : current_booking?.unit_type?.full_price?.[0]?.nsfas_deposit > 0
                                                                        ? "R " + current_booking?.unit_type?.full_price?.[0]?.nsfas_deposit
                                                                        : current_booking?.unit_type?.nsfas_deposit === 0 || current_booking?.unit_type?.nsfas_deposit === null
                                                                        ? "N/A"
                                                                        : "R " + current_booking?.unit_type?.nsfas_deposit}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {initialValues.student_type == 2
                                                                        ? current_bed?.full_price?.[0]?.bursary_deposit > 0
                                                                            ? "R " + current_bed?.full_price?.[0]?.bursary_deposit
                                                                            : "R " + current_bed?.unit_type?.bursary_deposit
                                                                        : initialValues.student_type == 3
                                                                        ? current_bed?.full_price?.[0]?.private_deposit > 0
                                                                            ? "R " + current_bed?.full_price?.[0]?.private_deposit
                                                                            : "R " + current_bed?.unit_type?.private_deposit
                                                                        : current_bed?.full_price?.[0]?.nsfas_deposit > 0
                                                                        ? "R " + current_bed?.full_price?.[0]?.nsfas_deposit
                                                                        : current_bed?.unit_type?.nsfas_deposit === 0 || current_bed?.unit_type?.nsfas_deposit === null
                                                                        ? "N/A"
                                                                        : "R " + current_bed?.unit_type?.nsfas_deposit}
                                                                </p>
                                                            )}
                                                        </div>

                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">BOOKING FEE: </p>
                                                            {!current_booking?.id ? (
                                                                <p>
                                                                    {current_bed?.full_price?.[0]?.bursary_booking_fee
                                                                        ? "R " + current_bed?.full_price?.[0]?.bursary_booking_fee
                                                                        : initialValues.student_type == 2
                                                                        ? "R " + current_bed?.unit_type?.bursary_booking_fee
                                                                        : initialValues.student_type == 3
                                                                        ? "R " + current_bed?.unit_type?.private_booking_fee
                                                                        : current_bed?.unit_type?.nsfas_booking_fee === 0 || current_bed?.unit_type?.nsfas_booking_fee === null
                                                                        ? "NSFAS Rate"
                                                                        : "R " + current_bed?.unit_type?.nsfas_booking_fee}
                                                                </p>
                                                            ) : (
                                                                <p>
                                                                    {current_booking?.unit_type?.full_price?.[0]?.bursary_booking_fee
                                                                        ? "R " + current_booking?.unit_type?.full_price?.[0]?.bursary_booking_fee
                                                                        : initialValues.student_type == 2
                                                                        ? "R " + current_booking?.unit_type?.bursary_booking_fee
                                                                        : initialValues.student_type == 3
                                                                        ? "R " + current_booking?.unit_type?.private_booking_fee
                                                                        : current_booking?.unit_type?.nsfas_booking_fee === 0 || current_booking?.unit_type?.nsfas_booking_fee === null
                                                                        ? "NSFAS Rate"
                                                                        : "R " + current_booking?.unit_type?.nsfas_booking_fee}
                                                                </p>
                                                            )}
                                                        </div>

                                                        <div className="flex flex-row gap-2">
                                                            <p className="font-bold">STUDY YEAR: </p>
                                                            <p>{yeartext != "" ? yeartext + " YEAR" : "NOT CHOSEN"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Panel>
                                        </Tab.Panels>
                                    </Tab.Group>
                                </div>
                                <div className="flex align-center justify-between">
                                    <div></div>
                                    <div className="text-right">
                                        <CustomButton text={"I am Happy"} onClick={() => setSatisfied(true)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize="true"
                    validateOnChange={true}
                    validateOnBlur={true}
                    validationSchema={Yup.object().shape({
                        title: Yup.number().required("title"),
                        name: Yup.string().required("name"),
                        surname: Yup.string().required("surname"),
                        email: Yup.string().required("email"),
                        date_of_birth: Yup.string().required("birthday"),
                        ethnicity: Yup.string().required("ethnicity"),
                        nationality: Yup.string().required("nationality"),
                        cell: Yup.string().required("A valid Cellphone number is required"),
                        id_number: Yup.string().required("id_number"),
                        institution_study: Yup.string().required("institution of study"),
                        institution_campus: Yup.string().required("institution campus"),
                        faculty: Yup.string().required("faculty"),
                        student_type: Yup.number().required("Student Type is Required"),
                        popi: Yup.number().positive("confirmation to the POPI Act"),

                        price_terms: Yup.number().positive("confirmation to the Pricing Terms"),
                        cash_bursary: Yup.number().positive("confirmation to the Cash and Bursary Terms"),
                        refundable_booking: Yup.number().positive("confirmation to the Refundable Booking"),
                        cash_back: Yup.number().positive("confirmation to the Cash Back"),
                        secure_bed: Yup.number().positive("confirmation to Secure Bed"),

                        kin_title: Yup.string().required("next of kin title"),
                        kin_name: Yup.string().required("next of kin name"),
                        kin_surname: Yup.string().required("next of kin surname"),
                        // kin_id_number: Yup.string().required("next of kin id number"),
                        kin_address: Yup.string().required("next of kin address"),
                        kin_suburb: Yup.string().required("next of kin suburb"),
                        kin_city: Yup.string().required("next of kin city"),
                        kin_postal: Yup.string().required("next of kin postal code"),
                        kin_relationship: Yup.string().required("relationship with your next of kin "),
                        // kin_tel_contact: Yup.string().required("next of kin tel number"),

                        kin_contact: Yup.string()
                            .required("next of kin cell number")
                            .notOneOf([Yup.ref("cell"), null], "cell and the next of kin cell to not match"),

                        kin_work_contact: Yup.string().required("next of kin work number"),
                        kin_email: Yup.string().required("next of kin email"),

                        parent_name: Yup.string().when("student_type", {
                            is: (value) => (value && value == 2) || value == 3,
                            then: Yup.string().required("responsible for funding name"),
                            otherwise: Yup.string().nullable(),
                        }),
                        parent_surname: Yup.string().when("student_type", {
                            is: (value) => (value && value == 2) || value == 3,
                            then: Yup.string().required("responsible for funding surname"),
                            otherwise: Yup.string().nullable(),
                        }),
                        parent_cell: Yup.string().when("student_type", {
                            is: (value) => (value && value == 2) || value == 3,
                            then: Yup.string().required("responsible for funding cell"),
                            otherwise: Yup.string().nullable(),
                        }),
                        parent_id_number: Yup.string().when("student_type", {
                            is: (value) => (value && value == 2) || value == 3,
                            then: Yup.string().required("responsible for funding id or passport number"),
                            otherwise: Yup.string().nullable(),
                        }),
                        parent_email: Yup.string().when("student_type", {
                            is: (value) => (value && value == 2) || value == 3,
                            then: Yup.string().required("responsible for funding email"),
                            otherwise: Yup.string().nullable(),
                        }),

                        assisted_by: Yup.string().required("information of where you heard from us"),
                        // password: Yup.string().required( "password" ),
                        // password_confirmation: Yup.string().required( "confirmation of password" ).oneOf( [ Yup.ref( 'password' ), null ], ' passwords and password confirmation to match' ),
                    })}
                    validateOnMount
                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                        setSubmitting(true);

                        let api_method = "post";
                        if (url && url != "0") {
                            api_method = "put";
                        }

                        //format cell
                        values.cell = values.cell && values.cell.replace("+", "");
                        // values.kin_tel_contact = values.kin_tel_contact && values.kin_tel_contact.replace("+", "");
                        values.kin_contact = values.kin_contact && values.kin_contact.replace("+", "");
                        values.kin_work_contact = values.kin_work_contact && values.kin_work_contact.replace("+", "");

                        if (values.referral_contact) {
                            values.referral_contact = values.referral_contact.replace("+", "");
                        }

                        if (values.parent_cell) {
                            values.parent_cell = values.parent_cell.replace("+", "");
                        }

                        await Object.keys(bankList).forEach((key) => {
                            if (values.banking_bank == bankList[key].label) {
                                values.banking_bank_code = bankList[key].code;
                            }
                        });

                        await axios({
                            method: api_method,
                            url: `${process.env.REACT_APP_API_URL}user`,
                            data: values,
                        })
                            .then((response) => {
                                if (url && url != "0") {
                                    toast(
                                        <NotificationToast
                                            title={"Application Updated"}
                                            message="Your application has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                        />
                                    );

                                    //Get data again
                                    axios({
                                        method: "get",
                                        url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
                                    })
                                        .then((response) => {
                                            setCurrentBooking(response.data);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            // window.location.href = "/404";
                                        });
                                } else {
                                    toast(
                                        <NotificationToast
                                            title={"Application Successful. Redirecting to Edit form"}
                                            // message="Your application has succesfully been created. Keep an eye out on your email and cellphone for future communication"
                                            message="Booking received, please keep an eye on your WhatsApps for more communication"
                                        />
                                    );
                                    window.onbeforeunload = function () {};
                                    window.location = window.location.origin + "/edit_application/" + response.data;
                                }

                                //Add the + back for form prettiness
                                values.cell = "+" + values.cell;
                                // values.kin_tel_contact = "+" + values.kin_tel_contact;
                                values.kin_contact = "+" + values.kin_contact;
                                values.kin_work_contact = "+" + values.kin_work_contact;

                                if (values.referral_contact) {
                                    values.referral_contact = "+" + values.referral_contact;
                                }

                                if (values.parent_cell) {
                                    values.parent_cell = "+" + values.parent_cell;
                                }
                            })
                            .catch((response) => {
                                toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                            });
                    }}
                >
                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <main className="mt-14">
                                <div className="max-w-7xl mx-auto pb-2 px-4">
                                    <div className="w-full">
                                        <div className="animate-pulse">
                                            <div className="bg-red-600 text-white py-2 px-5 rounded-3xl ">
                                                <p>
                                                    <strong>Your bed is not secured yet!</strong>
                                                    {url !== "0" ? (
                                                        <>
                                                            <br />
                                                            Follow these steps to get your renewal application finalized:
                                                            <br />
                                                            1. <strong>Review</strong> your application summary <strong>below</strong>
                                                            <br />
                                                            2. <strong>Approve</strong> the Terms and Conditions (Toggle buttons <strong>on</strong>)<br />
                                                            3. Click <strong>'SAVE'</strong> at the bottom of the page
                                                        </>
                                                    ) : (
                                                        <>
                                                            <br />
                                                            Please Complete the form and Submit your application
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className="sticky top-2 text-right z-50">
                                        
                                        <SubmitButton
                                            name="Save"
                                            disabled={!isValid || isSubmitting || !dirty}
                                            isSubmitting={isSubmitting}
                                            text={url && url != "0" ? "Save" : "Submit Application"}
                                            style={{
                                                fontWeight: 700,
                                                borderRadius: 20,
                                                marginBottom: 20,
                                            }}
                                        />
                                    </div> */}
                                        <div>
                                            {url && (
                                                <p className="text-gray-600 mt-4">
                                                    Scroll down to approve <span className="italic font-bold">terms and conditions</span> in order to save your application. Update your information at
                                                    each <span className="italic font-bold">Section</span> and update your application by clicking{" "}
                                                    <span className="italic font-bold">Submit Application</span>.
                                                </p>
                                            )}

                                            {(values.price_terms !== 1 || values.cash_bursary !== 1 || values.refundable_booking !== 1 || values.cash_back !== 1 || values.secure_bed !== 1) &&
                                            Object.keys(errors).length == 0 ? (
                                                <>
                                                    <p className="mt-6 text-red-500 text-lg font-bold">Cannot apply!</p>
                                                    <p className="my-2 text-red-500 text-sm">Please ensure you have checked all the checkboxes below</p>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                            {Object.keys(errors).length > 0 && (
                                                <>
                                                    <p className="mt-6 text-red-500 text-lg font-bold">Cannot apply!</p>{" "}
                                                    <p className="my-2 text-red-500 text-sm">Your application has errors that needs to be resolved below:</p>
                                                    {/* <ul role="lis" className="pt-4 px-8 mx-auto w-full text-orange-500 grid grid-cols-2 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                                                        {Object.keys(errors).map((key) => (
                                                            <li key={key} className="col-span-1 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                We require your <span className="font-bold italic">{errors[key]}</span>
                                                            </li>
                                                        ))}
                                                    </ul> */}
                                                </>
                                            )}
                                        </div>

                                        <div className="xl:grid xl:grid-cols-12">
                                            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                                                <Tab.List
                                                    className="pb-6 lg:pb-24 bg-light_background xl:col-span-2 w-full xl:w-72 border-0"
                                                    style={{ borderTopLeftRadius: "32px", borderBottomLeftRadius: "32px" }}
                                                >
                                                    {subNavigation.map((item) => (
                                                        <Tab
                                                            key={item.name}
                                                            className={({ selected }) =>
                                                                classNames(
                                                                    "group w-full text-sm flex items-center hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none mb-6 rounded-l-full",
                                                                    selected ? "bg-gray-100 text-primary" : "bg-light_background"
                                                                )
                                                            }
                                                        >
                                                            <div className="bg-primary mr-5 h-16 w-16 rounded-full flex items-center justify-center text-white">
                                                                <FontAwesomeIcon icon={item.icon} aria-hidden="true" style={{ fontSize: "22px" }} />
                                                            </div>
                                                            {item.name}
                                                        </Tab>
                                                    ))}
                                                </Tab.List>
                                                <Tab.Panels className="xl:ml-20 my-6 xl:my-0 bg-gray-100 xl:col-span-10" style={{ borderTopRightRadius: "32px", borderBottomRightRadius: "32px" }}>
                                                    <Tab.Panel className="xl:col-span-9 pb-12">
                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-2 gap-12 xl:px-6 uppercase">
                                                            <div>
                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">BUILDING: </p>
                                                                    <p>{!current_booking?.id ? current_bed?.property?.name : current_booking?.property?.name}</p>
                                                                </div>

                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">FLOOR: </p>
                                                                    <p>{!current_booking?.id ? current_bed?.unit?.floor.name : current_booking?.unit?.floor?.name}</p>
                                                                </div>

                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">Bed: </p>
                                                                    <p>
                                                                        {/* {!current_booking?.id ? current_bed?.unit?.name + " " + current_bed?.name : current_booking?.unit?.name + " " + current_booking?.bed?.name} */}
                                                                        {!current_booking?.id
                                                                            ? current_bed?.unit?.name + " " + current_bed?.name + (props.waitbed > 0 ? " or similar" : "")
                                                                            : (current_booking?.waiting_bed_id > 0 ? current_booking?.waiting_bed?.name : current_booking?.bed?.name) +
                                                                              (current_booking?.waiting_bed_id > 0 ? " or similar." : "")}
                                                                    </p>
                                                                </div>

                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">ROOM TYPE: </p>
                                                                    <p>{!current_booking?.id ? current_bed?.unit_type?.name : current_booking?.unit_type?.name}</p>
                                                                </div>

                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">GENDER: </p>
                                                                    <p>
                                                                        {current_booking?.user?.profile?.gender === 1
                                                                            ? "Male"
                                                                            : current_booking?.user?.profile?.gender === 2
                                                                            ? "Female"
                                                                            : gender == 1
                                                                            ? "Male"
                                                                            : gender == 2
                                                                            ? "Female"
                                                                            : "NOT CHOSEN"}
                                                                    </p>
                                                                </div>
                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">MONTHLY RENTAL: </p>
                                                                    {!current_booking?.id ? (
                                                                        <p>
                                                                            {values.student_type == 2
                                                                                ? "R " + current_bed?.unit_type?.bursary_price + "/pm"
                                                                                : values.student_type == 3
                                                                                ? "R " + current_bed?.unit_type?.private_price + "/pm"
                                                                                : current_bed?.unit_type?.nsfas_price === 0
                                                                                ? "NSFAS Rate"
                                                                                : "R " + current_bed?.unit_type?.nsfas_price + "/pm"}
                                                                        </p>
                                                                    ) : (
                                                                        <p>
                                                                            {values.student_type == 2
                                                                                ? "R " + current_booking?.unit_type?.bursary_price + "/pm"
                                                                                : values.student_type == 3
                                                                                ? "R " + current_booking?.unit_type?.private_price + "/pm"
                                                                                : current_booking?.unit_type?.nsfas_price === 0
                                                                                ? "NSFAS Rate"
                                                                                : "R " + current_booking?.unit_type?.nsfas_price + "/pm"}
                                                                        </p>
                                                                    )}
                                                                </div>

                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">{values.student_type == 2 || values.student_type == 3 ? "DEPOSIT" : "UPFRONT NON-REFUNDABLE FEE"}: </p>
                                                                    {!current_booking?.id ? (
                                                                        <p>
                                                                            {values.student_type == 2
                                                                                ? "R " + current_bed?.unit_type?.bursary_deposit
                                                                                : values.student_type == 3
                                                                                ? "R " + current_bed?.unit_type?.private_deposit
                                                                                : current_bed?.unit_type?.nsfas_deposit === 0 || current_bed?.unit_type?.nsfas_deposit === null
                                                                                ? "N/A"
                                                                                : "R " + current_bed?.unit_type?.nsfas_deposit}
                                                                        </p>
                                                                    ) : (
                                                                        <p>
                                                                            {values.student_type == 2
                                                                                ? "R " + current_booking?.unit_type?.bursary_deposit
                                                                                : values.student_type == 3
                                                                                ? "R " + current_booking?.unit_type?.private_deposit
                                                                                : current_booking?.unit_type?.nsfas_deposit === 0 || current_booking?.unit_type?.nsfas_deposit === null
                                                                                ? "N/A"
                                                                                : "R " + current_booking?.unit_type?.nsfas_deposit}
                                                                        </p>
                                                                    )}
                                                                </div>

                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">BOOKING FEE: </p>
                                                                    {!current_booking?.id ? (
                                                                        <p>
                                                                            {values.student_type == 2
                                                                                ? "R " + current_bed?.unit_type?.bursary_booking_fee
                                                                                : values.student_type == 3
                                                                                ? "R " + current_bed?.unit_type?.private_booking_fee
                                                                                : current_bed?.unit_type?.nsfas_booking_fee === 0 || current_bed?.unit_type?.nsfas_booking_fee === null
                                                                                ? "NSFAS Rate"
                                                                                : "R " + current_bed?.unit_type?.nsfas_booking_fee}
                                                                        </p>
                                                                    ) : (
                                                                        <p>
                                                                            {values.student_type == 2
                                                                                ? "R " + current_booking?.unit_type?.bursary_booking_fee
                                                                                : values.student_type == 3
                                                                                ? "R " + current_booking?.unit_type?.private_booking_fee
                                                                                : current_booking?.unit_type?.nsfas_booking_fee === 0 || current_booking?.unit_type?.nsfas_booking_fee === null
                                                                                ? "NSFAS Rate"
                                                                                : "R " + current_booking?.unit_type?.nsfas_booking_fee}
                                                                        </p>
                                                                    )}
                                                                </div>

                                                                <div className="flex flex-row gap-2">
                                                                    <p className="font-bold">STUDY YEAR: </p>
                                                                    <p>{yeartext != "" ? yeartext + " YEAR" : "NOT CHOSEN"}</p>
                                                                </div>
                                                            </div>

                                                            <Field
                                                                name="price_terms"
                                                                as={SettingsSwitch}
                                                                required
                                                                label="Pricing terms"
                                                                description="ALL PRICES ARE GUIDE PRICES AND SHOULD THE 2024 NSFAS RATES CHANGE, URBAN CIRCLE RESERVES THE RIGHT TO ALTER THE PRICING"
                                                            />
                                                            <Field
                                                                name="cash_bursary"
                                                                as={SettingsSwitch}
                                                                required
                                                                label="Cash and Bursary"
                                                                description="ALL CASH AND BURSAR STUDENTS TO PAY ONE MONTHS RENT AS REFUNDABLE DEPOSIT"
                                                            />
                                                            <Field
                                                                name="refundable_booking"
                                                                as={SettingsSwitch}
                                                                required
                                                                label="refundable booking"
                                                                description="BOOKING FEE REFUNDABLE ONLY AFTER MOVED IN AND FIRST MONTHS PAYMENT / SWIPE"
                                                            />
                                                            <Field name="cash_back" as={SettingsSwitch} required label="Cash back" description="CASH BACK ONLY PAYABLE ONCE FIRST SWIPE IS CONCLUDED" />
                                                            <Field
                                                                name="secure_bed"
                                                                as={SettingsSwitch}
                                                                required
                                                                label="secure bed"
                                                                description="CHECK IN AT PROPERTY ON OR BEFORE 7 FEBRUARY 2024 FOR ALL BUILDINGS TO SECURE BED"
                                                            />
                                                        </div>

                                                        <div className="text-center">
                                                            {url !== "0" ? (
                                                                <SubmitButton
                                                                    name="Save"
                                                                    disabled={!isValid || isSubmitting || !dirty}
                                                                    isSubmitting={isSubmitting}
                                                                    text={url && url != "0" ? "Save" : "Submit Application"}
                                                                    style={{
                                                                        fontWeight: 700,
                                                                        borderRadius: 20,
                                                                        marginBottom: 20,
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {errors && !(errors.price_terms || errors.cash_bursary || errors.refundable_booking || errors.secure_bed || errors.cash_back) ? (
                                                                        <div className="flex justify-around">
                                                                            <CustomButton text={"Next step"} onClick={() => setSelectedIndex(selectedIndex + 1)} />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                            <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                            <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </Tab.Panel>

                                                    <Tab.Panel className="xl:col-span-9 pb-12">
                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                            <Field
                                                                name="name"
                                                                type="text"
                                                                as={SettingsTextInput}
                                                                label="First Name"
                                                                required
                                                                disabled={url !== "" && url != "0" && initialValues.name !== "" ? true : false}
                                                            />

                                                            <Field
                                                                name="surname"
                                                                type="text"
                                                                as={SettingsTextInput}
                                                                label="Surname"
                                                                required
                                                                disabled={url !== "" && url != "0" && initialValues.surname !== "" ? true : false}
                                                            />

                                                            <Field name="email" type="email" as={SettingsTextInput} label="Email Address" required />

                                                            {/* <SettingsSelect
                                                                        label="Gender"
                                                                        name="gender"
                                                                        required
                                                                        onChange={ setFieldValue }
                                                                        onBlur={ setFieldTouched }
                                                                        value={ values.gender }
                                                                        touched={ touched.gender }
                                                                        error={ errors.gender }
                                                                        options="gender"
                                                                    /> */}

                                                            <SettingsSelect
                                                                label="Title"
                                                                name="title"
                                                                required
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.title}
                                                                touched={touched.title}
                                                                error={errors.title}
                                                                options="titles"
                                                            />

                                                            <Field
                                                                name="date_of_birth"
                                                                type="text"
                                                                as={SettingsDatePicker}
                                                                label="Birthday"
                                                                id_number={values.id_number}
                                                                nationality={values.nationality}
                                                                required
                                                                disabled={url !== "" && url != "0" && initialValues.date_of_birth !== "" ? true : false}
                                                            />

                                                            <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Cellphone Number</span>
                                                                    {errors.cell && <p className="text-xs font-light text-red-400">required</p>}
                                                                </label>
                                                                <div className="mt-1">
                                                                    <PhoneInput
                                                                        defaultCountry="ZA"
                                                                        countryCallingCodeEditable={false}
                                                                        onChange={(val) => {
                                                                            setFieldValue("cell", val);
                                                                        }}
                                                                        onBlur={setFieldTouched}
                                                                        name="cell"
                                                                        value={values.cell}
                                                                    />
                                                                    <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                                                </div>
                                                            </div>

                                                            <Field
                                                                name="student_number"
                                                                type="text"
                                                                as={SettingsTextInput}
                                                                label="Student Number"
                                                                disabled={url !== "" && url != "0" && initialValues.student_number !== "" ? true : false}
                                                            />

                                                            <SettingsSelect
                                                                label="Ethnicity"
                                                                name="ethnicity"
                                                                required
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.ethnicity}
                                                                touched={touched.ethnicity}
                                                                error={errors.ethnicity}
                                                                options={lookups ? lookups[2] : null}
                                                            />

                                                            <SettingsSelect
                                                                label="Nationality"
                                                                name="nationality"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.nationality}
                                                                touched={touched.nationality}
                                                                error={errors.nationality}
                                                                options="nationality"
                                                                required
                                                            />
                                                            <Field
                                                                name="id_number"
                                                                type="text"
                                                                as={SettingsTextInput}
                                                                label="ID or Passport Number"
                                                                required
                                                                nationality={values.nationality}
                                                                disabled={url !== "" && url != "0" && initialValues.id_number !== "" ? true : false}
                                                            />

                                                            <SettingsSelect
                                                                label="Institution of Study"
                                                                name="institution_study"
                                                                required
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.institution_study}
                                                                touched={touched.institution_study}
                                                                error={errors.institution_study}
                                                                options={lookups ? lookups[4] : null}
                                                            />
                                                            <SettingsSelect
                                                                label="Institution Campus"
                                                                name="institution_campus"
                                                                required
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.institution_campus}
                                                                touched={touched.institution_campus}
                                                                error={errors.institution_campus}
                                                                options={lookups ? lookups[5] : null}
                                                            />

                                                            <Field name="faculty" required type="text" as={SettingsTextInput} label="Faculty" />

                                                            <div className="col-span-2 sm:col-span-3 transition ease-linear delay-50">
                                                                <label htmlFor="student_type" className="text-sm font-medium text-gray-700">
                                                                    Student Type
                                                                </label>
                                                                <Select
                                                                    id="student_type"
                                                                    name="student_type"
                                                                    options={student_types}
                                                                    onChange={(val) => {
                                                                        setFieldValue("student_type", val.value);
                                                                        setType(val.value);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    value={student_types && student_types.length > 0 ? student_types.find((option) => option.value == values.student_type) : ""}
                                                                    components={{
                                                                        IndicatorSeparator: () => null,
                                                                    }}
                                                                    styles={customStyles}
                                                                    className="mt-1 block border font-light border-gray-300 rounded-lg shadow-sm py-2 px-3 focus:outline-primary sm:text-sm transition ease-linear duration-10 hover:border-primary"
                                                                />
                                                                <p className="text-red-400 text-sm">{errors.student_type}</p>
                                                            </div>

                                                            {/* {values.student_type == 3 ? 
                                                            <> */}
                                                            <SettingsSelect
                                                                label="Bank"
                                                                name="banking_bank"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.banking_bank}
                                                                touched={touched.banking_bank}
                                                                error={errors.banking_bank}
                                                                options={bankList}
                                                            />
                                                            <Field name="banking_account_type" type="text" as={SettingsTextInput} label="Account Type" />
                                                            <Field name="banking_branch" type="text" as={SettingsTextInput} label="Branch" />
                                                            <Field name="banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" />
                                                            <Field name="banking_account_number" type="text" as={SettingsTextInput} label="Account Number" />
                                                            <Field name="banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" />
                                                            {/* </>
                                                        : ""} */}

                                                            {/* <SettingsSelect
                                                            label="STUDENT YEAR"
                                                            name="study_year"
                                                            required
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.study_year}
                                                            touched={touched.study_year}
                                                            error={errors.study_year}
                                                            options={lookups ? lookups[8] : null}
                                                        /> */}
                                                        </div>

                                                        <div className="py-8 px-4 xl:px-6 uppercase">
                                                            <Field
                                                                name="popi"
                                                                as={SettingsSwitch}
                                                                required
                                                                label="I Agree to the POPI ACT"
                                                                description="QPG Holdings Proprietary Limited and its subsidiaries, which includes Urban Circle, is compliant with the Protection of Personal Information Act (POPIA), also known as the POPI Act, which is mandatory for all organisations in South Africa. POPI makes it illegal for us to collect, use or store the personal information of customers and businesses unless it is done in accordance with the laws and regulations prescribed in the POPI Act."
                                                            />
                                                        </div>
                                                        <div className="flex justify-around">
                                                            <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} />
                                                            {!(
                                                                errors.name ||
                                                                errors.surname ||
                                                                errors.email ||
                                                                errors.title ||
                                                                errors.date_of_birth ||
                                                                errors.cell ||
                                                                errors.ethnicity ||
                                                                errors.nationality ||
                                                                errors.id_number ||
                                                                errors.institution_study ||
                                                                errors.institution_campus ||
                                                                errors.faculty ||
                                                                // errors.student_number ||
                                                                // errors.student_type ||
                                                                // errors.banking_bank ||
                                                                // errors.banking_account_type ||
                                                                // errors.banking_branch ||
                                                                // errors.banking_branch_code ||
                                                                // errors.banking_account_number ||
                                                                // errors.banking_account_holder ||
                                                                errors.popi
                                                            ) ? (
                                                                <div className="flex justify-around">
                                                                    <CustomButton text={"Next step"} onClick={() => setSelectedIndex(selectedIndex + 1)} />
                                                                </div>
                                                            ) : (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                    <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {/* TODO: button here */}
                                                    </Tab.Panel>

                                                    <Tab.Panel className="xl:col-span-9 pb-12">
                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                            <SettingsSelect
                                                                label="Title"
                                                                name="kin_title"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.kin_title}
                                                                touched={touched.kin_title}
                                                                error={errors.kin_title}
                                                                options="titles"
                                                                required
                                                            />
                                                            <Field name="kin_name" type="text" as={SettingsTextInput} label="Name" required />
                                                            <Field name="kin_surname" type="text" as={SettingsTextInput} label="Surname" required />
                                                            {/* <Field name="kin_id_number" type="text" as={SettingsTextInput} label="ID Number" required /> */}
                                                            <Field name="kin_address" type="text" as={SettingsTextInput} label="Address" required />
                                                            <Field name="kin_suburb" type="text" as={SettingsTextInput} label="Suburb" required />
                                                            <Field name="kin_city" type="text" as={SettingsTextInput} label="City" required />
                                                            <Field name="kin_postal" type="text" as={SettingsTextInput} label="Postal Code" required />
                                                            <Field name="kin_relationship" type="text" as={SettingsTextInput} label="Relationship" required />
                                                            {/* <Field name="kin_tel_contact" type="text" as={SettingsTextInput} label="Telephone Number" prepend="+" required /> */}

                                                            {/* <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="kin_tel_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Telephone Number</span>
                                                                {errors.kin_tel_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("kin_tel_contact", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="kin_tel_contact"
                                                                    value={values.kin_tel_contact}
                                                                />
                                                                <p className="text-red-400 text-sm font-light">{errors.kin_tel_contact}</p>
                                                            </div>
                                                        </div> */}

                                                            <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                <label htmlFor="kin_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Cellphone Number</span>
                                                                    {errors.kin_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                                </label>
                                                                <div className="mt-1">
                                                                    <PhoneInput
                                                                        defaultCountry="ZA"
                                                                        countryCallingCodeEditable={false}
                                                                        onChange={(val) => {
                                                                            setFieldValue("kin_contact", val);
                                                                        }}
                                                                        onBlur={setFieldTouched}
                                                                        name="kin_contact"
                                                                        value={values.kin_contact}
                                                                    />
                                                                    <p className="text-red-400 text-sm font-light">{errors.kin_contact}</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                <label htmlFor="kin_work_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Work Number</span>
                                                                    {errors.kin_work_contact && <p className="text-xs font-light text-red-400">required</p>}
                                                                </label>
                                                                <div className="mt-1">
                                                                    <PhoneInput
                                                                        defaultCountry="ZA"
                                                                        countryCallingCodeEditable={false}
                                                                        onChange={(val) => {
                                                                            setFieldValue("kin_work_contact", val);
                                                                        }}
                                                                        onBlur={setFieldTouched}
                                                                        name="kin_work_contact"
                                                                        value={values.kin_work_contact}
                                                                    />
                                                                    <p className="text-red-400 text-sm font-light">{errors.kin_work_contact}</p>
                                                                </div>
                                                            </div>

                                                            {/* <Field name="kin_contact" type="text" as={SettingsTextInput} label="Cellphone Number" prepend="+" required /> */}
                                                            {/* <Field name="kin_work_contact" type="text" as={SettingsTextInput} label="Work Number" prepend="+" required /> */}
                                                            <Field name="kin_email" type="email" as={SettingsTextInput} label="Email" required />
                                                        </div>
                                                        <div className="flex justify-around">
                                                            <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} />
                                                            {!(
                                                                errors.kin_name ||
                                                                errors.kin_surname ||
                                                                errors.kin_address ||
                                                                errors.kin_suburb ||
                                                                errors.kin_city ||
                                                                errors.kin_postal ||
                                                                errors.kin_relationship ||
                                                                errors.kin_contact ||
                                                                errors.kin_work_contact ||
                                                                errors.kin_email
                                                            ) ? (
                                                                <div className="flex justify-around">
                                                                    <CustomButton text={"Next step"} onClick={() => setSelectedIndex(selectedIndex + 1)} />
                                                                </div>
                                                            ) : (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                    <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Tab.Panel>

                                                    <Tab.Panel className="xl:col-span-9 pb-12">
                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                            <Field name="referral_name" as={SettingsTextInput} label="First Name" />
                                                            <Field name="referral_surname" as={SettingsTextInput} label="Surname" />
                                                            {/* <Field name="referral_contact" as={SettingsTextInput} label="Cellphone Number" prepend="+" /> */}

                                                            <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                <label htmlFor="referral_contact" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                    <span>Cellphone Number</span>
                                                                </label>
                                                                <div className="mt-1">
                                                                    <PhoneInput
                                                                        defaultCountry="ZA"
                                                                        countryCallingCodeEditable={false}
                                                                        onChange={(val) => {
                                                                            setFieldValue("referral_contact", val);
                                                                        }}
                                                                        onBlur={setFieldTouched}
                                                                        name="referral_contact"
                                                                        value={values.referral_contact}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <Field name="referral_id_number" as={SettingsTextInput} label="ID. Number" />
                                                        </div>
                                                        <div className="pb-8 px-4 xl:px-6 uppercase">
                                                            <SettingsSelect
                                                                label="Referral Type"
                                                                name="referral"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.referral}
                                                                touched={touched.referral}
                                                                error={errors.referral}
                                                                options={lookups ? lookups[1] : null}
                                                            />
                                                            {values.referral === 50 && <Field name="referral_other" as={SettingsTextInput} label="What is the other Referral?" />}

                                                            <div className="py-8 space-y-6 sm:space-y-0 uppercase">
                                                                <Field name="assisted_by" as={SettingsTextInput} label="Where did you hear about us?" required />
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-around">
                                                            <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} />
                                                            {(current_booking && (current_booking?.student_type == 2 || current_booking?.student_type == 3)) || props.type == 2 || props.type == 3 ? (
                                                                <>
                                                                    {!errors.assisted_by ? (
                                                                        <div className="flex justify-around">
                                                                            <CustomButton text={"Next step"} onClick={() => setSelectedIndex(selectedIndex + 2)} />
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <SubmitButton
                                                                        name="Save"
                                                                        disabled={!isValid || isSubmitting || !dirty}
                                                                        isSubmitting={isSubmitting}
                                                                        text={url && url != "0" ? "Save" : "Submit Application"}
                                                                        style={{
                                                                            fontWeight: 700,
                                                                            borderRadius: 20,
                                                                            marginBottom: 20,
                                                                            width: "100%",
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    </Tab.Panel>

                                                    <Tab.Panel className="xl:col-span-9 pb-12">
                                                        {!current_booking?.id ? (
                                                            <div className="py-8 px-4 xl:px-6">Please save your application before uploading files</div>
                                                        ) : (
                                                            <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                                {current_booking &&
                                                                current_booking?.user?.name &&
                                                                current_booking?.user?.surname &&
                                                                current_booking?.user?.cell &&
                                                                current_booking?.user?.email &&
                                                                current_booking?.user?.profile?.id_number ? (
                                                                    <>
                                                                        <div className="col-span-6 sm:col-span-3">
                                                                            <p>We require the following files from you:</p>

                                                                            {values.student_type == 3 && (
                                                                                <ul className="text-sm ml-4">
                                                                                    <li>Sponsor: 3 months bank statement</li>
                                                                                    <li>Sponsor: Recent payslip</li>
                                                                                    <li>Sponsor: Proof of residence</li>
                                                                                    <li>Sponsor: ID / Passport</li>
                                                                                    <li>Student ID / Passport</li>
                                                                                    <li>Student Card</li>
                                                                                    <li>Latest Academic Record</li>
                                                                                    {/* <li>Proof of Registration</li> */}
                                                                                </ul>
                                                                            )}

                                                                            {values.student_type == 2 && (
                                                                                <ul className="text-sm ml-4">
                                                                                    <li>Student ID / Passport</li>
                                                                                    <li>Student Card</li>
                                                                                    <li>Latest Bursary Letter</li>
                                                                                    <li>Latest Academic Record</li>
                                                                                    {/* <li>Proof of Registration</li> */}
                                                                                    {/* <li>ID</li> */}
                                                                                </ul>
                                                                            )}

                                                                            {values.student_type != 2 && values.student_type != 3 && (
                                                                                <ul className="text-sm ml-4">
                                                                                    <li>Student Card</li>
                                                                                    <li>Student ID / Passport</li>
                                                                                    <li>Proof of NSFAS funding or fee statement</li>
                                                                                    <li>Latest Academic Record</li>
                                                                                    {/* <li>Proof of Registration</li> */}
                                                                                    {/* <li>Proof of Nsfas</li> */}
                                                                                </ul>
                                                                            )}
                                                                        </div>
                                                                        <div className="col-span-6 sm:col-span-3">
                                                                            {current_booking?.id && (
                                                                                <>
                                                                                    <FileUpload setCurrentBooking={setCurrentBooking} current_booking={current_booking} url={url} file_type="" />

                                                                                    {current_booking?.files && current_booking.files.length > 0 ? (
                                                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                                            <ul role="list" className="border border-dark_background rounded-md divide-y divide-dark_background">
                                                                                                {current_booking.files.map((file) => (
                                                                                                    <FileDisplaySimple file={file} key={file.id} />
                                                                                                ))}
                                                                                            </ul>
                                                                                        </dd>
                                                                                    ) : (
                                                                                        <p>No files have been uploaded</p>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="col-span-6 sm:col-span-3">
                                                                            <p>Please fill in the Application Form first</p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Tab.Panel>

                                                    {((current_booking && (current_booking?.student_type == 2 || current_booking?.student_type == 3)) || props.type == 2 || props.type == 3) && (
                                                        <Tab.Panel className="xl:col-span-9 pb-12">
                                                            <h2 className="pt-6 px-4 text-center leading-6 font-light text-dark_gray capitalize">PARENT / GUARDIANS / SPONSOR / BURSARY DETAILS</h2>
                                                            <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                <Field name="parent_name" type="text" as={SettingsTextInput} label="First Name" required />
                                                                <Field name="parent_surname" type="text" as={SettingsTextInput} label="Surname" required />
                                                                {/* <Field name="parent_cell" type="text" as={SettingsTextInput} prepend="+" label="Cellphone Number" required /> */}
                                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                                    <label htmlFor="parent_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                        <span>Cellphone Number</span>
                                                                    </label>
                                                                    <div className="mt-1">
                                                                        <PhoneInput
                                                                            defaultCountry="ZA"
                                                                            countryCallingCodeEditable={false}
                                                                            onChange={(val) => {
                                                                                setFieldValue("parent_cell", val);
                                                                            }}
                                                                            onBlur={setFieldTouched}
                                                                            name="parent_cell"
                                                                            value={values.parent_cell}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Field name="parent_id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" required />
                                                            </div>
                                                            <div className="pb-8 px-4 xl:px-6">
                                                                <Field name="parent_email" type="email" as={SettingsTextInput} label="Email Address" required />
                                                            </div>

                                                            {!(errors.parent_name || errors.parent_surname || errors.parent_cell || errors.parent_id_number || errors.parent_email) ? (
                                                                <SubmitButton
                                                                    name="Save"
                                                                    disabled={!isValid || isSubmitting || !dirty}
                                                                    isSubmitting={isSubmitting}
                                                                    text={url && url != "0" ? "Save" : "Submit Application"}
                                                                    style={{
                                                                        fontWeight: 700,
                                                                        borderRadius: 20,
                                                                        marginBottom: 20,
                                                                        width: "100%",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                        <span className="font-bold italic">Please fill in all the required information before Submitting your profile.</span>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Tab.Panel>
                                                    )}

                                                    {current_booking && current_booking.rates && current_booking.rates.length > 0 && (
                                                        <Tab.Panel className="xl:col-span-9 p-4">
                                                            <PaymentForm showbtn="no" />
                                                        </Tab.Panel>
                                                    )}
                                                </Tab.Panels>
                                            </Tab.Group>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
}
