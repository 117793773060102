import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tab } from "@headlessui/react";
import SettingsSelect from "../Forms/SettingsSelect";
import { Formik, Form, Field } from "formik";
import SettingsTextInput from "../Forms/SettingsTextInput";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import SubmitButton from "../../components/Forms/SubmitButton";
import * as Yup from "yup";
import { toast } from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import SettingsSwitch from "../Forms/SettingsSwitch";
import CustomButton from "../Helpers/CustomButton";
import FileUpload from "../Forms/FileUpload";
import FileDisplaySimple from "../Forms/FileDisplaySimple";
import PhoneInput from "react-phone-number-input";

export default function BankDetailsUC() {
    const [userID, setUserID] = useState("");
    const [showError, setShowError] = useState("");
    const [current_booking, setCurrentBooking] = useState({});
    const [bankList, setBankList] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [fileUploadInformation, setFileUploadInformation] = useState([]);
    const [otpSent, setOtpSent] = useState(false);

    useEffect(async () => {
        if (bankList.length == 0) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}paystack_banks`,
            })
                .then((response) => {
                    let banks = response.data.data;
                    let bankList = [];

                    Object.keys(banks).forEach((key) => {
                        bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                    });

                    setBankList(bankList);
                })
                .catch((error) => {
                    alert("Bank failed");
                });
        }
    });

    return (
        <div>
            <div className="uc">
                <div className="pb-32 elative bg-dark_background">
                    <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                        <div className="sm:flex items-center">
                            <img className="h-24" src="/images/logo_uc.svg" alt="Urban Circle" />
                            <div className="py-6 lg:py-0 mx-auto md:w-96 text-center text-white text-4xl uppercase font-bold">
                                <h1>Banking Details</h1>
                            </div>
                            <div>
                                <a
                                    target="_blank"
                                    href="https://api.whatsapp.com/send?phone=270872325511"
                                    className="h-12 rounded-full text-white px-5 flex justify-between items-center font-bold"
                                    style={{ background: "#62D43F" }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25.364" height="25.364" viewBox="0 0 25.364 25.364" className="mr-5">
                                        <path
                                            id="Icon_simple-whatsapp"
                                            data-name="Icon simple-whatsapp"
                                            d="M18.492,15.2c-.318-.159-1.867-.916-2.156-1.021s-.5-.159-.711.159-.815,1.019-1,1.228-.369.222-.683.079a8.577,8.577,0,0,1-2.54-1.569,9.588,9.588,0,0,1-1.754-2.188c-.184-.317-.02-.491.137-.65.144-.143.318-.365.477-.553a2.679,2.679,0,0,0,.314-.524.581.581,0,0,0-.026-.554c-.079-.159-.71-1.712-.974-2.331s-.515-.539-.71-.539c-.182-.016-.392-.016-.6-.016a1.191,1.191,0,0,0-.842.379,3.528,3.528,0,0,0-1.1,2.616,6.184,6.184,0,0,0,1.288,3.25A14.092,14.092,0,0,0,13,17.705c.755.317,1.342.507,1.8.665a4.372,4.372,0,0,0,1.987.128,3.252,3.252,0,0,0,2.131-1.507,2.616,2.616,0,0,0,.19-1.506c-.078-.143-.285-.222-.6-.365m-5.756,7.866H12.73a10.572,10.572,0,0,1-5.342-1.458l-.38-.226-3.963,1.03L4.106,18.48l-.253-.4A10.482,10.482,0,0,1,20.178,5.153a10.344,10.344,0,0,1,3.074,7.387,10.5,10.5,0,0,1-10.5,10.447M21.686,3.645A12.832,12.832,0,0,0,12.73,0,12.565,12.565,0,0,0,1.792,18.852L0,25.364l6.7-1.746a12.748,12.748,0,0,0,6.035,1.529h.006A12.619,12.619,0,0,0,25.364,12.575,12.465,12.465,0,0,0,21.67,3.686"
                                            fill="#fff"
                                        />
                                    </svg>
                                    <span className="mr-3">NEED HELP?</span>
                                </a>
                            </div>
                        </div>
                    </header>
                </div>
            </div>

            <main className="-mt-32">
                <div>
                    <div style={{ borderTopRightRadius: "50px" }} className="bg-white min-h-screen shadow-md rounded-tl-3xl sm:-ml-52 sm:mr-52 sm:pl-52">
                        <div className="h-20 flex">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div className="text-white text-2xl font-bold uppercase">Please update your banking details</div>
                            </div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        {!current_booking.id ? (
                            <div>
                                {otpSent === false ? (
                                    <Formik
                                        initialValues={{ id_number: "", cell: "" }}
                                        enableReinitialize="true"
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                        validationSchema={Yup.object().shape({
                                            id_number: Yup.string().required("id_number"),
                                            cell: Yup.string().required("cell"),
                                        })}
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}update_details_otp`,
                                                data: values,
                                            })
                                                .then((response) => {
                                                    if (response.data == "error") {
                                                        setShowError("We cannot find your details. Please make sure you have entered the correct details");
                                                    } else if (response.data > 0) {
                                                        setOtpSent(true);
                                                        setUserID(response.data);
                                                        setShowError("");
                                                    } else {
                                                        setShowError("Something went wrong. Please try again later.");
                                                    }
                                                })
                                                .catch((response) => {
                                                    toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4 mt-10">
                                                <div className="max-w-6xl mx-auto">
                                                    <Tab.Group>
                                                        <Tab.Panels className="my-6 xl:my-0 rounded-3xl bg-gray-100 xl:col-span-9 mx-auto">
                                                            <Tab.Panel>
                                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                                    <p className="text-center font-bold">We need to verify your details first</p>
                                                                    <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                        <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" required />

                                                                        <div className="col-span-2 sm:col-span-3">
                                                                            <label className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">Whatsapp Number</label>
                                                                            <PhoneInput
                                                                                defaultCountry="ZA"
                                                                                countryCallingCodeEditable={false}
                                                                                onChange={(val) => {
                                                                                    setFieldValue("cell", val);
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                name="cell"
                                                                                value={values.cell}
                                                                            />
                                                                            <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                                                        </div>
                                                                    </div>

                                                                    <br />
                                                                    <SubmitButton
                                                                        name="Update"
                                                                        disabled={!isValid || isSubmitting || !dirty}
                                                                        isSubmitting={isSubmitting}
                                                                        icon={faSave}
                                                                        text="Send OTP"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    />

                                                                    {showError && <p className="text-red-500 text-sm font-ligh text-center mt-4 font-bold">{showError}</p>}
                                                                </div>
                                                            </Tab.Panel>
                                                        </Tab.Panels>
                                                    </Tab.Group>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                ) : (
                                    <Formik
                                        initialValues={{ otp: "", user_id: userID }}
                                        enableReinitialize="true"
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                        validationSchema={Yup.object().shape({
                                            otp: Yup.string().required("otp"),
                                        })}
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}check_otp`,
                                                data: values,
                                            })
                                                .then((response) => {
                                                    if (response.data == "error") {
                                                        setShowError("Incorrect OTP. Please try again.");
                                                    } else if (response.data == "expired") {
                                                        setShowError("Your OTP has expired, we have sent a new one.");
                                                    } else if (response.data.id > 0) {
                                                        setCurrentBooking(response.data);

                                                        setInitialValues({
                                                            banking_bank: response.data?.user?.profile?.banking_bank ? response.data.user.profile.banking_bank : null,
                                                            banking_account_type: response.data?.user?.profile?.banking_account_type ? response.data.user.profile.banking_account_type : null,
                                                            banking_branch: response.data?.user?.profile?.banking_branch ? response.data.user.profile.banking_branch : null,
                                                            banking_branch_code: response.data?.user?.profile?.banking_branch_code ? response.data.user.profile.banking_branch_code : null,
                                                            banking_account_number: response.data?.user?.profile?.banking_account_number ? response.data.user.profile.banking_account_number : null,
                                                            banking_account_holder: response.data?.user?.profile?.banking_account_holder ? response.data.user.profile.banking_account_holder : null,
                                                            edit_url: response.data?.url,
                                                        });
                                                    } else {
                                                        alert("Something went wrong. Please try again later.");
                                                    }
                                                })
                                                .catch((response) => {
                                                    toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4 mt-10">
                                                <div className="max-w-6xl mx-auto">
                                                    <Tab.Group>
                                                        <Tab.Panels className="my-6 xl:my-0 rounded-3xl bg-gray-100 xl:col-span-9 mx-auto">
                                                            <Tab.Panel>
                                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                                    <div className="py-8 px-4 space-y-6 sm:space-y-0 xl:px-6 uppercase">
                                                                        <Field name="otp" type="text" as={SettingsTextInput} label="Please enter your OTP" required />
                                                                    </div>

                                                                    <br />
                                                                    <SubmitButton
                                                                        name="Update"
                                                                        disabled={!isValid || isSubmitting || !dirty}
                                                                        isSubmitting={isSubmitting}
                                                                        icon={faSave}
                                                                        text="Check OTP"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    />

                                                                    {showError && <p className="text-red-500 text-sm font-ligh text-center mt-4 font-bold">{showError}</p>}
                                                                </div>
                                                            </Tab.Panel>
                                                        </Tab.Panels>
                                                    </Tab.Group>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </div>
                        ) : (
                            <>
                                {current_booking &&
                                current_booking?.user?.name &&
                                current_booking?.user?.surname &&
                                current_booking?.user?.cell &&
                                current_booking?.user?.email &&
                                current_booking?.user?.profile?.id_number ? (
                                    <>
                                        <Formik
                                            initialValues={initialValues}
                                            enableReinitialize="true"
                                            validateOnChange={true}
                                            validateOnBlur={true}
                                            validationSchema={Yup.object().shape({
                                                banking_account_holder: Yup.string().required("banking_account_holder"),
                                                banking_account_number: Yup.string().required("banking_account_number"),
                                                banking_account_type: Yup.string().required("banking_account_type"),
                                                banking_bank: Yup.string().required("banking_bank"),
                                                banking_branch: Yup.string().required("banking_branch"),
                                                banking_branch_code: Yup.string().required("banking_branch_code"),
                                                details: Yup.number()
                                                    .positive("Confirm that your banking details are correct")
                                                    .test("is-details-one", "Please confirm that your banking details are correct", function (value) {
                                                        return value === 1;
                                                    }),
                                            })}
                                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                console.log(current_booking?.files);

                                                //Check if we have uploaded files
                                                if (current_booking?.files?.length > 0) {
                                                    setSubmitting(true);

                                                    await Object.keys(bankList).forEach((key) => {
                                                        if (values.banking_bank == bankList[key].label) {
                                                            values.banking_bank_code = bankList[key].code;
                                                        }
                                                    });

                                                    await axios({
                                                        method: "post",
                                                        url: `${process.env.REACT_APP_API_URL}user_banking_details`,
                                                        data: values,
                                                    })
                                                        .then((response) => {
                                                            toast(
                                                                <NotificationToast
                                                                    title={"Banking Details Updated"}
                                                                    message="Your Banking Details has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                                                />
                                                            );
                                                        })
                                                        .catch((response) => {
                                                            toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                        });
                                                } else {
                                                    alert("Please upload a file");
                                                }
                                            }}
                                        >
                                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4 mt-10">
                                                    <div className="max-w-6xl mx-auto">
                                                        <Tab.Group>
                                                            <Tab.Panels className="my-6 xl:my-0 rounded-3xl bg-gray-100 xl:col-span-9 mx-auto">
                                                                <Tab.Panel>
                                                                    <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                            <SettingsSelect
                                                                                label="Bank"
                                                                                name="banking_bank"
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                value={values.banking_bank}
                                                                                touched={touched.banking_bank}
                                                                                error={errors.banking_bank}
                                                                                options={bankList}
                                                                                required
                                                                            />
                                                                            <Field name="banking_account_type" type="text" as={SettingsTextInput} label="Account Type" required />
                                                                            <Field name="banking_branch" type="text" as={SettingsTextInput} label="Branch" required />
                                                                            <Field name="banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" required />
                                                                            <Field name="banking_account_number" type="text" as={SettingsTextInput} label="Account Number" required />
                                                                            <Field name="banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" required />

                                                                            <div className="col-span-6">
                                                                                <p>Bank confirmation letter / Bank card</p>

                                                                                <FileUpload
                                                                                    current_booking={current_booking}
                                                                                    setCurrentBooking={setCurrentBooking}
                                                                                    fileUploadInformation={fileUploadInformation}
                                                                                    file_type="BankConfirmation"
                                                                                    url={values.edit_url}
                                                                                />

                                                                                {current_booking.files && current_booking.files.length > 0 && (
                                                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                                        <ul role="list" className="border border-dark_background rounded-md divide-y divide-dark_background">
                                                                                            {current_booking.files.map((file) => (
                                                                                                <>{file.file_type === "BankConfirmation" && <FileDisplaySimple file={file} key={file.id} />}</>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </dd>
                                                                                )}
                                                                            </div>
                                                                        </div>

                                                                        <div className="py-8 px-4 uppercase">
                                                                            <Field
                                                                                name="details"
                                                                                as={SettingsSwitch}
                                                                                required
                                                                                label="I Confirm my banking details"
                                                                                description="I hereby confirm that the banking details I have provided are accurate and that I will not hold Urban Circle liable should these details be incorrect."
                                                                            />
                                                                            {errors.details && (
                                                                                <div className="text-orange-500 col-span-2 text-sm font-light">Please confirm that your details are correct</div>
                                                                            )}
                                                                        </div>

                                                                        <br />
                                                                        <SubmitButton
                                                                            name="Update"
                                                                            disabled={!isValid || isSubmitting || !dirty}
                                                                            isSubmitting={isSubmitting}
                                                                            icon={faSave}
                                                                            text="Update"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </Tab.Panel>
                                                            </Tab.Panels>
                                                        </Tab.Group>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                ) : (
                                    <>
                                        <div className="mx-auto bg-white max-w-7xl">
                                            <main className="mt-14">
                                                <div className="max-w-7xl mx-auto pb-2 px-4">
                                                    <div className="w-full">
                                                        {/* <p>Please fill in the Application Form first</p> */}
                                                        <p>You are missing some information on your application.</p>
                                                        <a
                                                            href={"/edit_application/" + current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url}
                                                            className="mt-2 relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                                        >
                                                            CLICK HERE TO EDIT YOUR PROFILE
                                                        </a>
                                                    </div>
                                                </div>
                                            </main>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
}
