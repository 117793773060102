import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import SettingsTextInput from "../../Forms/SettingsTextInput";
import PhoneInput from "react-phone-number-input";
import ApplicationHeaderUC from "../ApplicationHeaderUC";
import SubmitButton from "../../Forms/SubmitButton";

export default function ApplicationFormUCStep1Short() {
    const [formDone, setFormDone] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({
        name: "",
        surname: "",
        email: "",
        cell: "",
        property: "",
        institution: "",
        campus: "",
        funding_type: "",
        room_type: "",
        uc_short_msg: true,
    });

    // Extract URL parameters and set them as initial values for the form
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setInitialFormValues({
            name: "",
            surname: "",
            email: "",
            cell: "",
            property: params.get("property") || "",
            institution: params.get("institution") || "",
            campus: params.get("campus") || "",
            funding_type: params.get("funding_type") || "",
            room_type: params.get("room_type") || "",
            uc_short_msg: true,
        });
    }, []);

    return (
        <div className="uc h-screen flex items-center justify-center bg-[#F7F7F7] px-8">
            <main className="w-full">
                <div className="mx-auto max-w-5xl py-6 rounded-lg">
                    {formDone == true ? (
                        <>
                            <p className="text-[#17273B] mt-4 text-xl text-center">
                                Thank You!
                                <br />
                                Please check your Whatsapp for the next step.
                            </p>
                            <p className="text-[#17273B] mt-4 font-bold text-xl text-center">You are one step closer to joining the inner circle!</p>
                        </>
                    ) : (
                        <Formik
                            initialValues={initialFormValues}
                            enableReinitialize
                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                setSubmitting(true);
                                axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}uc_step1_short`,
                                    data: values,
                                })
                                    .then((response) => {
                                        if (response.data == "User already exists" || response.data == "The email has already been taken.") {
                                            setErrors({ cell: "User already exists, please contact support." });
                                        } else {
                                            setFormDone(true);
                                        }
                                        setSubmitting(false);
                                    })
                                    .catch((error) => {
                                        setErrors({ cell: "User already exists, please contact support." });
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="md:py-6 lg:py-0 sm:text-center sm:font-bold mb-4">
                                        <h1 className="text-[#17273B] text-md sm:text-xl">Student Accommodation Simplified</h1>
                                        <h2 className="text-4xl sm:text-7xl text-[#e61b49] md:mb-8">Apply now!</h2>

                                        <p className="mb-4 text-[#17273b]/50 text-[21px] sm:text-center">
                                            You can use the below button to apply now on WhatsApp or you can complete the below form and an agent will contact you
                                        </p>
                                        <a
                                            target="_blank"
                                            href="https://api.whatsapp.com/send?phone=270872325511"
                                            className="px-5 py-2 rounded-[10px] border uppercase justify-center items-center gap-2.5 inline-flex text-white text-xs bg-[#00AFC3]"
                                        >
                                            APPLY WITH WHATSAPP
                                        </a>
                                    </div>

                                    <div className="md:grid grid-cols-2 gap-4 space-y-4 md:space-y-0">
                                        <div>
                                            <label htmlFor="email" className="text-sm font-medium text-black">
                                                Name
                                            </label>
                                            <Field className="bg-[#17273b]/10 border-none" name="name" type="text" as={SettingsTextInput} label="" required maxLength={50} />
                                        </div>

                                        <div>
                                            <label htmlFor="email" className="text-sm font-medium text-black">
                                                Surname
                                            </label>
                                            <Field className="bg-[#17273b]/10 border-none" name="surname" type="text" as={SettingsTextInput} label="" required maxLength={50} />
                                        </div>

                                        <div>
                                            <label htmlFor="email" className="text-sm font-medium text-black">
                                                Email Address
                                            </label>
                                            <Field className="bg-[#17273b]/10 border-none" name="email" type="email" as={SettingsTextInput} label="" maxLength={100} />
                                        </div>

                                        <div className="ucnew">
                                            <label htmlFor="email" className="text-sm font-medium text-black">
                                                Whatsapp Number
                                            </label>
                                            <PhoneInput
                                                defaultCountry="ZA"
                                                countryCallingCodeEditable={false}
                                                onChange={(val) => {
                                                    setFieldValue("cell", val);
                                                }}
                                                onBlur={setFieldTouched}
                                                name="cell"
                                                value={values.cell}
                                                maxLength={12}
                                            />
                                            <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                        </div>
                                    </div>

                                    {(values.property !== "" || values.institution !== "" || values.campus !== "" || values.funding_type !== "" || values.room_type !== "") && (
                                        <div className="mt-8 text-center">
                                            <div className="font-bold">Chosen</div>
                                            <div>{values.property ? "Property: " + values.property : ""}</div>
                                            <div>{values.institution ? "Institution: " + values.institution : ""}</div>
                                            <div>{values.campus ? "Campus: " + values.campus : ""}</div>
                                            <div>{values.institution ? "Funding Type: " + values.funding_type : ""}</div>
                                            <div>{values.room_type ? "Room Type: " + values.room_type : ""}</div>
                                        </div>
                                    )}

                                    <div className="text-center mt-8 md:flex md:justify-center md:items-center md:space-x-4 space-y-4 md:space-y-0">
                                        {!isSubmitting ? (
                                            <SubmitButton
                                                name="Save"
                                                disabled={!isValid || isSubmitting || !dirty}
                                                isSubmitting={isSubmitting}
                                                text="Submit Application"
                                                className="w-full md:w-auto"
                                                style={{
                                                    fontWeight: 700,
                                                    borderRadius: 10,
                                                    fontSize: 12,
                                                    padding: "5px 30px",
                                                    // marginBottom: 20,
                                                    // width: "100%",
                                                }}
                                            />
                                        ) : (
                                            <p>Please wait...</p>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </main>
        </div>
    );
}
