import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { SvgLoader, SvgProxy } from "react-svgmt";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SettingsSelect from "../Forms/SettingsSelect";
import { openModal } from "../../store/modal/modalReducer";
import { useDispatch } from "react-redux";
import ApplicationHeaderUC from "./ApplicationHeaderUC";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import { Tab } from "@headlessui/react";
import PhoneInput from "react-phone-number-input";
import SettingsTextInput from "../Forms/SettingsTextInput";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import SubmitButton from "../../components/Forms/SubmitButton";

export default function UCChooseBed() {
    const dispatch = useDispatch();

    const [unitTypes, setUnittypes] = useState([]);
    const [floor, setFloor] = useState(0);
    const [floors, setFloors] = useState([]);
    const [property, setProperty] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lookups, setLookups] = useState([]);
    const [blocked, setBlocked] = useState("");
    const [reserved, setReserved] = useState("");
    const [current_booking, setCurrentBooking] = useState({});
    const [currentYear, setCurrentYear] = useState(0);
    const [showSearch, setShowSearch] = useState(true);
    const [otpSent, setOtpSent] = useState(false);
    const [showError, setShowError] = useState("");
    const [userID, setUserID] = useState("");
    const [initialValues, setInitialValues] = useState({});
    const [bedChosen, setBedChosen] = useState(false);

    console.log(current_booking);

    // ENABLE WHEN GO LIVE
    // window.onbeforeunload = function ()
    // {
    //     return "Data will be lost if you leave the page, are you sure?";
    // };
    useEffect(async () => {
        setLoading(true);

        console.log(current_booking);
        console.log(current_booking?.edit_link?.edit_url);

        if (current_booking?.property_id > 0) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}get_property_floors/${current_booking?.property_id}/1`,
            })
                .then((response) => {
                    setFloors(response.data.floors);
                    setUnittypes(response.data.unit_types);
                    setProperty(response.data.property);

                    setLoading(false);
                })
                .catch((error) => {
                    alert("properties failed");
                });

            // axios({
            //     method: "get",
            //     url: `${process.env.REACT_APP_API_URL}uc_lookups_for_students`,
            // })
            //     .then((response) => {
            //         setLookups(response.data);
            //     })
            //     .catch((error) => {
            //         alert("lookups failed");
            //     });
        }

        //Get bookings for this floor
        if (current_booking?.floor_id > 0) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}get_current_year`,
            })
                .then((responseYear) => {
                    let year_data = responseYear.data.year_id;
                    setCurrentYear(year_data);

                    let call_url = current_booking?.edit_link?.edit_url ?? 0;

                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${current_booking?.floor_id}/1/${year_data}/${call_url}`,
                    })
                        .then((response) => {
                            let var_blocked = ".blocked";
                            if (response.data !== "") {
                                var_blocked += "," + response.data;
                            }
                            // console.log(var_blocked)
                            setBlocked(var_blocked);

                            setLoading(false);
                        })
                        .catch((error) => {
                            alert("failed to get bookings");
                        });

                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}get_floor_bed_bookings/${current_booking?.floor_id}/5/${year_data}/${call_url}`,
                    })
                        .then((response) => {
                            let var_blocked = ".movedIn";
                            if (response.data !== "") {
                                var_blocked += "," + response.data;
                            }

                            // console.log(var_blocked)
                            setReserved(var_blocked);

                            setLoading(false);
                        })
                        .catch((error) => {
                            alert("failed to get bookings");
                        });
                })
                .catch((error) => {
                    alert("lookups failed");
                });
        }
    }, [current_booking]);

    if (
        current_booking &&
        current_booking.rates &&
        current_booking.rates.length > 0 &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== "" &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== null
    ) {
        return (
            <main className="mt-14">
                <div className="max-w-7xl mx-auto pb-2 px-4">
                    <h2>Your lease has been signed, no futher updates to your information are allowed.</h2>
                </div>
            </main>
        );
    }

    // return (
    //     <div className="uc">
    //         <ApplicationHeaderUC />
    //         <main className="-mt-32">
    //             <div>
    //                 <div className="bg-white min-h-screen shadow-md rounded-tl-3xl white_block_left">
    //                     <div className="h-20 flex sticky top-0 z-30">
    //                         <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
    //                             <div>
    //                                 <p className="text-white sm:text-xs md:text-s ">Use the map below to choose a bed.</p>
    //                                 <p className="text-white sm:text-xs md:text-2xl font-bold uppercase">
    //                                     CHOOSE A BED {current_booking && current_booking.year ? " - " + current_booking.year.year : ""}
    //                                 </p>
    //                             </div>
    //                         </div>
    //                         <div className="w-1/5 colour-uc4 z-20" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
    //                         <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
    //                     </div>
    //                     <div className="frontend_svg">
    //                         <TransformWrapper initialScale={1}>
    //                             <TransformComponent>
    //                                 <SvgLoader path="https://api.urbancircle.co.za/api/get_svg/528" style={{ width: "100vw" }}>
    //                                     <SvgProxy
    //                                         selector={".mapped"}
    //                                         fill="red"
    //                                         onClick={(e) => {
    //                                             alert("blocked");
    //                                         }}
    //                                     />

    //                                     <SvgProxy
    //                                         selector={".mapped.room_type_567"}
    //                                         fill="rgb(227,227,227)"
    //                                         // onClick={(e) => {
    //                                         //     dispatch(
    //                                         //         openModal({
    //                                         //             modal_type: "ModalChooseBed",
    //                                         //             modal_props: {
    //                                         //                 url: current_booking?.edit_link?.edit_url,
    //                                         //                 property_id: values.property_id,
    //                                         //                 type: values.type,
    //                                         //                 year: values.year,
    //                                         //                 gender: values.gender,
    //                                         //                 bed_id: e.currentTarget.getAttribute("bedid"),
    //                                         //                 setBedChosen: setBedChosen,
    //                                         //             },
    //                                         //         })
    //                                         //     );
    //                                         // }}
    //                                     />
    //                                 </SvgLoader>
    //                             </TransformComponent>
    //                         </TransformWrapper>
    //                     </div>
    //                 </div>
    //             </div>
    //         </main>
    //     </div>
    // );

    return (
        <div className="uc">
            <ApplicationHeaderUC />
            <main className="-mt-32">
                <div>
                    <div className="bg-white min-h-screen shadow-md rounded-tl-3xl white_block_left">
                        <div className="h-20 flex sticky top-0 z-30">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div>
                                    <p className="text-white sm:text-xs md:text-s ">Use the map below to choose a bed.</p>
                                    <p className="text-white sm:text-xs md:text-2xl font-bold uppercase">
                                        CHOOSE A BED {current_booking && current_booking.year ? " - " + current_booking.year.year : ""}
                                    </p>
                                </div>
                            </div>
                            <div className="w-1/5 colour-uc4 z-20" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        <div className="mx-auto bg-white max-w-7xl">
                            {bedChosen === true ? (
                                <div className="text-center mt-8">
                                    <h1 className="text-3xl mb-4">You have chosen a bed</h1>
                                    <p>Check Whatsapp for further details</p>
                                </div>
                            ) : (
                                <>
                                    {!current_booking.id ? (
                                        <div>
                                            {otpSent === false ? (
                                                <Formik
                                                    // initialValues={{ id_number: "8303045032083", cell: "+27823895956" }}
                                                    initialValues={{ id_number: "", cell: "" }}
                                                    enableReinitialize="true"
                                                    validateOnChange={true}
                                                    validateOnBlur={true}
                                                    validationSchema={Yup.object().shape({
                                                        id_number: Yup.string().required("id_number"),
                                                        cell: Yup.string().required("cell"),
                                                    })}
                                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                        setSubmitting(true);

                                                        await axios({
                                                            method: "post",
                                                            url: `${process.env.REACT_APP_API_URL}update_details_otp`,
                                                            data: values,
                                                        })
                                                            .then((response) => {
                                                                if (response.data == "error") {
                                                                    setShowError("We cannot find your details. Please make sure you have entered the correct details");
                                                                } else if (response.data > 0) {
                                                                    setOtpSent(true);
                                                                    setUserID(response.data);
                                                                    setShowError("");
                                                                } else {
                                                                    setShowError("Something went wrong. Please try again later.");
                                                                }
                                                            })
                                                            .catch((response) => {
                                                                toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                            });
                                                    }}
                                                >
                                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4 mt-10">
                                                            <div className="max-w-6xl mx-auto">
                                                                <Tab.Group>
                                                                    <Tab.Panels className="my-6 xl:my-0 rounded-3xl bg-gray-100 xl:col-span-9 mx-auto">
                                                                        <Tab.Panel>
                                                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                                                <p className="text-center font-bold">We need to verify your details first</p>
                                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                                    <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" required />

                                                                                    <div className="col-span-2 sm:col-span-3">
                                                                                        <label className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">
                                                                                            Whatsapp Number
                                                                                        </label>
                                                                                        <PhoneInput
                                                                                            defaultCountry="ZA"
                                                                                            countryCallingCodeEditable={false}
                                                                                            onChange={(val) => {
                                                                                                setFieldValue("cell", val);
                                                                                            }}
                                                                                            onBlur={setFieldTouched}
                                                                                            name="cell"
                                                                                            value={values.cell}
                                                                                        />
                                                                                        <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                                                                    </div>
                                                                                </div>

                                                                                <br />
                                                                                <SubmitButton
                                                                                    name="Update"
                                                                                    disabled={!isValid || isSubmitting || !dirty}
                                                                                    isSubmitting={isSubmitting}
                                                                                    icon={faSave}
                                                                                    text="Send OTP"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                    }}
                                                                                />

                                                                                {showError && <p className="text-red-500 text-sm font-ligh text-center mt-4 font-bold">{showError}</p>}
                                                                            </div>
                                                                        </Tab.Panel>
                                                                    </Tab.Panels>
                                                                </Tab.Group>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            ) : (
                                                <Formik
                                                    initialValues={{ otp: "", user_id: userID }}
                                                    enableReinitialize="true"
                                                    validateOnChange={true}
                                                    validateOnBlur={true}
                                                    validationSchema={Yup.object().shape({
                                                        otp: Yup.string().required("otp"),
                                                    })}
                                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                        setSubmitting(true);

                                                        await axios({
                                                            method: "post",
                                                            url: `${process.env.REACT_APP_API_URL}check_otp`,
                                                            data: values,
                                                        })
                                                            .then((response) => {
                                                                if (response.data == "error") {
                                                                    setShowError("Incorrect OTP. Please try again.");
                                                                } else if (response.data == "expired") {
                                                                    setShowError("Your OTP has expired, we have sent a new one.");
                                                                } else if (response.data.id > 0) {
                                                                    setCurrentBooking(response.data);

                                                                    setInitialValues({
                                                                        banking_bank: response.data?.user?.profile?.banking_bank ? response.data.user.profile.banking_bank : null,
                                                                        banking_account_type: response.data?.user?.profile?.banking_account_type
                                                                            ? response.data.user.profile.banking_account_type
                                                                            : null,
                                                                        banking_branch: response.data?.user?.profile?.banking_branch ? response.data.user.profile.banking_branch : null,
                                                                        banking_branch_code: response.data?.user?.profile?.banking_branch_code ? response.data.user.profile.banking_branch_code : null,
                                                                        banking_account_number: response.data?.user?.profile?.banking_account_number
                                                                            ? response.data.user.profile.banking_account_number
                                                                            : null,
                                                                        banking_account_holder: response.data?.user?.profile?.banking_account_holder
                                                                            ? response.data.user.profile.banking_account_holder
                                                                            : null,
                                                                        edit_url: response.data?.url,
                                                                    });
                                                                } else {
                                                                    alert("Something went wrong. Please try again later.");
                                                                }
                                                            })
                                                            .catch((response) => {
                                                                toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                            });
                                                    }}
                                                >
                                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4 mt-10">
                                                            <div className="max-w-6xl mx-auto">
                                                                <Tab.Group>
                                                                    <Tab.Panels className="my-6 xl:my-0 rounded-3xl bg-gray-100 xl:col-span-9 mx-auto">
                                                                        <Tab.Panel>
                                                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 xl:px-6 uppercase">
                                                                                    <Field name="otp" type="text" as={SettingsTextInput} label="Please enter your OTP" required />
                                                                                </div>

                                                                                <br />
                                                                                <SubmitButton
                                                                                    name="Update"
                                                                                    disabled={!isValid || isSubmitting || !dirty}
                                                                                    isSubmitting={isSubmitting}
                                                                                    icon={faSave}
                                                                                    text="Check OTP"
                                                                                    style={{
                                                                                        width: "100%",
                                                                                    }}
                                                                                />

                                                                                {showError && <p className="text-red-500 text-sm font-ligh text-center mt-4 font-bold">{showError}</p>}
                                                                            </div>
                                                                        </Tab.Panel>
                                                                    </Tab.Panels>
                                                                </Tab.Group>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            )}
                                        </div>
                                    ) : (
                                        <Formik
                                            initialValues={{
                                                type: current_booking?.student_type,
                                                year: current_booking?.year_id,
                                                gender: current_booking?.user?.profile?.gender,
                                                room_type: current_booking?.unit_type,
                                                floor_id: current_booking?.floor_id,
                                            }}
                                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                setFloor(values.floor_id);
                                            }}
                                        >
                                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <div className="text-center mb-8">
                                                        <div
                                                            className="mt-10 mb-5 inline-block text-white rounded-full px-8 py-5 uppercase font-bold text-2xl"
                                                            style={{ backgroundColor: "#00AFB1", border: "5px solid #24344C" }}
                                                        >
                                                            <h2>{current_booking?.property?.name}</h2>
                                                        </div>

                                                        <div className="text-gray-500 font-bold">
                                                            <FontAwesomeIcon className=" mr-2" icon={solid("location-dot")} />
                                                            {current_booking?.property?.address}, {current_booking?.property?.suburb}, {current_booking?.property?.postal}
                                                        </div>
                                                    </div>
                                                    <div className="mx-auto bg-white max-w-5xl">
                                                        <div className="my-6 mx-4 sm:xm-0 sm:my-12 colour-uc6 rounded-3xl py-4 sm:py-7 px-8 sm:px-20 md:flex md:justify-between md:items-center">
                                                            {showSearch === true ? (
                                                                <>
                                                                    <div className="md:flex md:w-4/5 flex-wrap">
                                                                        <div className="md:w-1/3 px-3 z-20">
                                                                            <SettingsSelect
                                                                                label="FLOOR"
                                                                                name="floor_id"
                                                                                required
                                                                                onChange={setFieldValue}
                                                                                onBlur={setFieldTouched}
                                                                                value={values.floor_id}
                                                                                touched={touched.floor_id}
                                                                                error={errors.floor_id}
                                                                                options={floors}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}

                                                            <div>
                                                                {showSearch === true ? (
                                                                    <>
                                                                        <button
                                                                            type="submit"
                                                                            className="w-full relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold mt-2"
                                                                        >
                                                                            SEARCH
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {floor > 0 && (
                                                        <h2 className="text-center text-xl">
                                                            Use the map below to <strong>CHOOSE A BED</strong>
                                                        </h2>
                                                    )}

                                                    {floor > 0 && blocked !== "" ? (
                                                        <>
                                                            <div className="flex flex-row justify-center items-center space-x-4 sm:space-x-12 mb-4">
                                                                <div className="flex space-x-2 sm:justify-center sm:items-center">
                                                                    <div className="w-5 h-5 rounded-full" style={{ backgroundColor: "red" }}></div>
                                                                    <div className="font-bold">Reserved</div>
                                                                </div>

                                                                <div className="flex space-x-2 sm:justify-center sm:items-center">
                                                                    <div className="w-5 h-5 rounded-full" style={{ backgroundColor: "#e5e7eb" }}></div>
                                                                    <div className="font-bold">Available</div>
                                                                </div>

                                                                <div className="flex space-x-2 sm:justify-center sm:items-center">
                                                                    <div className="w-5 h-5 rounded-full" style={{ backgroundColor: "green" }}></div>
                                                                    <div className="font-bold">Selected</div>
                                                                </div>
                                                            </div>

                                                            <p className="text-center mb-4">Move the map and pinch to zoom in</p>

                                                            <div className="frontend_svg" style={{ touchAction: "none" }}>
                                                                <TransformWrapper
                                                                    initialScale={1}
                                                                    minScale={1}
                                                                    maxScale={4}
                                                                    doubleClick={{ disabled: true }} // Disable double-click to avoid accidental zooms
                                                                    wheel={{ step: 0.5 }} // Adjusts scroll zoom sensitivity
                                                                    pinch={{ step: 0.03 }} // Adjusts pinch zoom sensitivity
                                                                >
                                                                    <TransformComponent>
                                                                        <SvgLoader path={process.env.REACT_APP_LARAVEL_URL + "api/get_svg/" + floor} style={{ width: "100vw" }}>
                                                                            <SvgProxy
                                                                                selector={".mapped"}
                                                                                fill="red"
                                                                                onClick={(e) => {
                                                                                    alert("blocked");
                                                                                }}
                                                                            />

                                                                            <SvgProxy
                                                                                selector={".mapped.room_type_" + current_booking?.unit_type}
                                                                                fill="rgb(227,227,227)"
                                                                                onClick={(e) => {
                                                                                    dispatch(
                                                                                        openModal({
                                                                                            modal_type: "ModalChooseBed",
                                                                                            modal_props: {
                                                                                                url: current_booking?.edit_link?.edit_url,
                                                                                                property_id: values.property_id,
                                                                                                type: values.type,
                                                                                                year: values.year,
                                                                                                gender: values.gender,
                                                                                                bed_id: e.currentTarget.getAttribute("bedid"),
                                                                                                setBedChosen: setBedChosen,
                                                                                            },
                                                                                        })
                                                                                    );
                                                                                }}
                                                                            />

                                                                            {/* <SvgProxy selector=".taken" fill="#ff0000" /> */}

                                                                            <SvgProxy selector=".resident_reserved" fill="black" />

                                                                            <SvgProxy selector="rect:hover, path:hover" fill="green" />

                                                                            {/* <SvgProxy selector={blocked} fill="red" onclick={() => console.log("blocked")} /> */}

                                                                            <SvgProxy
                                                                                selector={blocked}
                                                                                fill="red"
                                                                                onclick={(e) => {
                                                                                    alert("This bed is already taken.");
                                                                                }}
                                                                            />

                                                                            {current_booking?.bed_id && current_booking?.bed_id > 0 && (
                                                                                <SvgProxy selector={"#bed_" + current_booking.bed_id} fill="green" />
                                                                            )}

                                                                            <SvgProxy selector={reserved} fill="deeppink" onclick={() => console.log("blocked")} />
                                                                        </SvgLoader>
                                                                    </TransformComponent>
                                                                </TransformWrapper>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="text-center">Choose filters to show a map</div>
                                                    )}
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
